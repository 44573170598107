import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import CardBalance from "../assets/svg/cards/balance.svg"
import CardDeposit from "../assets/svg/cards/deposit.svg"
import CardBonus from "../assets/svg/cards/bonus.svg"
import CardEarning from "../assets/svg/cards/earnings.svg"
import CardUnilevel from "../assets/svg/cards/unilevel.svg"
import CardProfit from "../assets/svg/cards/profit.svg"
import CardInvite from "../assets/svg/cards/invite.svg"
import AppContext from "../context/AppContext";
import toast from "react-hot-toast";
import Countdown from "../components/Countdown";
import GlobalVolumen from "../components/GlobalVolumen";
import QRCode from "react-qr-code";
import PackageCard from "../components/PackageCard";
import Cookies from "js-cookie"; // Importa la librería de cookies

import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, ArcElement, Legend } from 'chart.js';
import { format } from "date-fns";

ChartJS.register(BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend);

export default function Home() {
  const { t } = useTranslation();
  const { loading, updateLoading } = useContext(AppContext);
  const navigate = useNavigate();
  const [percentage, setPercentage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [rangeData, setRangeData] = useState([]);
  const [packagesOperation, setPackagesOperation] = useState([]);
  const [emptyPackage, setEmptyPackage] = useState(false);
  const [hoursServer, setHoursServer] = useState();
  const [profitDay, setProfitDay] = useState(0);
  const [balance, setBalance] = useState(0);
  const [balanceTotal, setBalanceTotal] = useState(0);
  const [earningsTotal, setEarningsTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [total, setTotal] = useState(0);
  const [accelerateButton, setAccelerateButton] = useState(Cookies.get(`accelerate`) ? true : false);
  useEffect(() => {
    getUsersData();
    getNotificationsPackages();
  }, []);

  const getUsersData = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/users`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const data = response?.data;
        setUserData(data);
        setRangeData(response?.range)
        if (data && data.package_value_maximun && data.package_value_current) {
          const max = data.package_value_maximun;
          const current = data.package_value_current;
          const calculatedPercentage = (current / max) * 100;
          setPercentage(calculatedPercentage);
        }
        setBalance(parseFloat(data?.balance_available) + parseFloat(data?.balance_bonus) + parseFloat(data?.balance_referral) + parseFloat(data?.balance_direct));
        setBalanceTotal(parseFloat(data?.total_available) + parseFloat(data?.total_bonus) + parseFloat(data?.total_referral) + parseFloat(data?.total_direct))
        setEarningsTotal(parseFloat(data?.total_available) + parseFloat(data?.total_bonus) + parseFloat(data?.total_referral) + parseFloat(data?.total_direct));
        setHoursServer(response?.hour);
        setProfitDay((response?.profit * 100).toFixed(1));
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }

  const getNotificationsPackages = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/notification-earnings?page=${page}&limit=${limit}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const data = response?.data?.items;
        if (data) {
          setPackagesOperation(data); // Establecer los datos agrupados y sumados
          setTotal(response?.data?.total)
        }
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }

  useEffect(() => {
    getNotificationsPackages();
  }, [page, limit]);



  const goToPackages = () => {
    navigate("/packages");
  }


  const nameNotification = (name) => {
    if (name === "3") {
      return t("_DASHBOARD._NOTIFICATION3");
    } else if (name === "2") {
      return t("_DASHBOARD._NOTIFICATION2");
    } else if (name === "1") {
      return t("_DASHBOARD._NOTIFICATION1");
    } else if (name === "5") {
      return t("_DASHBOARD._NOTIFICATION5");
    } else if (name === "4") {
      return t("_DASHBOARD._NOTIFICATION4");
    } else if (name === "7") {
      return t("_DASHBOARD._NOTIFICATION7");
    }
  }


  const copyShared = (code) => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_FRONTEND}/signup?invite=${code}`
    );
    toast.success(t("_DASHBOARD._COPYMESSAGE"));
  };

  const oraculo = (props) => {
    if (window.localStorage.getItem("tron")) {
      let value = props / window.localStorage.getItem("tron");
      let fomart = Number.parseFloat(value).toFixed(3);
      let valueFormat = formatNumber(fomart);
      return valueFormat;
    }
  };

  const formatNumber = (value) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    const valueString = value.toString();
    const [integerPart, decimalPart] = valueString.split('.');

    if (decimalPart && decimalPart.length > 2) {
      return `${integerPart}.${decimalPart.slice(0, 2)}`;
    }

    return valueString;
  };


  const goToAccelerate = () => {
    Cookies.set('accelerate', true, { expires: 1 / 96 });
    setAccelerateButton(true);
    fetch(`${process.env.REACT_APP_BACKEND}/deposit`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        toast.success(t("_AUTH._SUCCESS"));
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }


  return (
    <div className="container-full">
      {/** Buttons speed */}
      <div className="flex flex-col lg:flex-row gap-8 h-auto">
        <div className="flex flex-col w-full lg:w-[78%] gap-4 lg:min-h-full lg:h-auto justify-between">
          <div className="flex flex-col lg:flex-row gap-8 mb-4">
            <div className="w-full h-[12.5rem] lg:w-1/3  rounded-lg shadow-md text-white bg-cover border-lime-600 border-2 bg-no-repeat group flex flex-col justify-between" style={{ backgroundImage: `url(${CardBalance})` }}>
              <h1 className="text-white text-2xl p-4">{t("_DASHBOARD._NOTIFICATION7")}</h1>
              <div className="flex justify-between items-center">
                <div></div>
                <div className="flex flex-col h-auto text-end justify-end font2 text-xl text-white  bg-zinc-900/80 lg:bg-transparent w-full px-4">
                  <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0 ">{formatNumber(userData?.balance_direct || 0)} USD </p>
                  <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0"> {formatNumber(userData?.total_direct || 0)} {t("_DASHBOARD._TOTAL")} </p>
                  <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(userData?.balance_direct)} TRX </p>
                  <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(userData?.total_direct)} {t("_DASHBOARD._TOTAL")} </p>
                </div>
              </div>
            </div>
            <div className="w-full h-[12.5rem] lg:w-1/3  rounded-lg shadow-md text-white bg-cover border-lime-600 border-2 bg-no-repeat group flex flex-col justify-between" style={{ backgroundImage: `url(${CardDeposit})` }}>
              <div className="flex justify-between p-4">
                <h1 className="text-white text-2xl">{t("_DASHBOARD._TITLE2")}</h1>
                {!accelerateButton && (
                  <button className="text-md w-auto border-2 p-1 cursor-pointer rounded-lg px-2" onClick={() => goToAccelerate()}>{t("_SYSTEM._ACCELERATE")} </button>
                )}
              </div>
              <div className="flex justify-between items-center">
                <div></div>
                <div className="flex flex-col h-auto text-end justify-end font2 text-xl text-white  bg-zinc-900/80 lg:bg-transparent w-full px-4">
                  <p className="block  transition-opacity duration-300 opacity-100 ">{formatNumber(userData?.balance_deposit || 0)} TRX </p>
                  <p className="block  transition-opacity duration-300 opacity-100 "> {formatNumber(userData?.total_deposit || 0)} {t("_DASHBOARD._TOTAL")}  </p>
                </div>
              </div>
            </div>
            <div className="w-full h-[12.5rem] lg:w-1/3  rounded-lg shadow-md text-white bg-cover border-lime-600 border-2 bg-no-repeat group flex flex-col justify-between" style={{ backgroundImage: `url(${CardEarning})` }}>
              <div className="flex justify-between p-4">
                <h1 className="text-white text-2xl">{t("_DASHBOARD._TITLE3")}</h1>
                {balance > 0 && (
                  <button className="text-md w-auto  cursor-pointer rounded-lg px-2 text-lime-400  flex justify-center gap-2" >
                    {formatNumber(balance || 0)}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" />
                    </svg>
                  </button>
                )}
              </div>
              <div className="flex justify-between items-center">
                <div></div>
                <div className="flex flex-col h-auto text-end justify-end font2 text-xl text-white  bg-zinc-900/80 lg:bg-transparent w-full px-4">
                  <p className="block transition-opacity duration-300 opacity-100 ">{formatNumber(earningsTotal || 0)} <small> USD </small></p>
                  <p className="block transition-opacity duration-300 opacity-100 "> {oraculo(earningsTotal)} <small> TRX </small> </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-8">
            <div className="w-full h-[12.5rem] lg:w-1/3  rounded-lg shadow-md text-white bg-cover border-lime-600 border-2 bg-no-repeat group flex flex-col justify-between" style={{ backgroundImage: `url(${CardUnilevel})` }}>
              <h1 className="text-white text-2xl p-4">{t("_DASHBOARD._TITLE4")}</h1>
              <div className="flex justify-between items-center">
                <div></div>
                <div className="flex flex-col h-auto text-end justify-end font2 text-xl text-white  bg-zinc-900/80 lg:bg-transparent w-full px-4">
                  <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0">{formatNumber(userData?.balance_referral || 0)} USD </p>
                  <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0">{formatNumber(userData?.total_referral || 0)} {t("_DASHBOARD._TOTAL")} </p>
                  <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(userData?.balance_referral)}  TRX </p>
                  <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(userData?.total_referral)}{t("_DASHBOARD._TOTAL")} </p>
                </div>
              </div>
            </div>
            <div className="w-full h-[12.5rem] lg:w-1/3  rounded-lg shadow-md text-white bg-cover border-lime-600 border-2 bg-no-repeat group flex flex-col justify-between" style={{ backgroundImage: `url(${CardBonus})` }}>
              <h1 className="text-white text-2xl p-4">{t("_DASHBOARD._TITLE5")}</h1>
              <div className="flex justify-between items-center">
                <div></div>
                <div className="flex flex-col h-auto text-end justify-end font2 text-xl text-white  bg-zinc-900/80 lg:bg-transparent w-full px-4">
                  <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0">{formatNumber(userData?.balance_bonus || 0)} USD </p>
                  <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0">{formatNumber(userData?.total_bonus || 0)} {t("_DASHBOARD._TOTAL")} </p>
                  <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(userData?.balance_bonus)}  TRX </p>
                  <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(userData?.total_bonus)}  {t("_DASHBOARD._TOTAL")} </p>
                </div>
              </div>
            </div>
            <PackageCard userData={userData} />
          </div>
        </div>
        <GlobalVolumen userData={userData} rangeData={rangeData} />
      </div>
      {/* 
      <div className="flex flex-col lg:flex-row gap-4 h-auto my-4 lg:mt-16">
        <div
          className="w-full md:w-1/2 border-lime-600 border-2 bg-zinc-800 rounded-lg shadow-md h-auto text-white p-4 bg-cover bg-no-repeat relative animate-border"
          style={{ backgroundImage: `url(${CardProfit})` }}
        >
          <div
            className="absolute -top-4 -right-2 bg-red-600 text-white text-xs font-bold py-1 px-2 rounded-full shadow-lg"
          >
            News
          </div>
          <h1 className="text-2xl font-bold mb-4">🚀 Signals</h1>
          <div className="flex gap-4">

            <div className="w-1/2 h-18">
              <Link to="/signals"><span className="uppercase underline text-lime-500 my-4">What is Cryptotradex Signal?</span></Link>
              <p>
                Join our exclusive Signals group and receive more than 10 daily recommendations to maximize your profits, with returns of up to 200% on high and low-risk investments.
              </p>
            </div>
            <div className="flex justify-center w-1/2">
              <img
                src="https://via.placeholder.com/100"
                alt="Signals"
                className="rounded-lg"
              />
            </div>
          </div>
          <div className="flex justify-center my-4">
            {formatNumber(userData?.total_deposit || 0) > 1 ?
              <button
                onClick={() => navigate('/mysignals')}
                className="bg-lime-600 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded-lg"
              >
                Go to Signals
              </button>
              :
              <button
                className="bg-gray-600  text-white font-bold py-2 px-4 rounded-lg cursor-not-allowed opacity-50"
              >
                Requerid min 1000 USD in deposit
              </button>
            }
          </div>
        </div>
        <div
          className="w-full md:w-1/2 border-lime-600 border-2 bg-zinc-800 rounded-lg shadow-md h-auto text-white p-4 bg-cover bg-no-repeat relative animate-border"
          style={{ backgroundImage: `url(${CardProfit})` }}
        >
          <div
            className="absolute -top-4 -right-2 bg-red-600 text-white text-xs font-bold py-1 px-2 rounded-full shadow-lg"
          >
            News
          </div>
          <h1 className="text-2xl font-bold mb-4">🎓 Academy</h1>
          <div className="flex flex-row gap-4">
            <div className="w-1/2 h-18">
              <Link to="/signals"><span className="uppercase underline text-lime-500 my-4">What is Cryptotradex Academy?</span></Link>
              <p>Learn about cryptocurrencies, wallets, memes, and Web3 with our online platform designed for all skill levels.</p>
            </div>
            <div className="flex justify-center w-1/2">
              <img
                src="https://via.placeholder.com/100"
                alt="Signals"
                className="object-contain rounded-lg"
              />
            </div>
          </div>
          <div className="flex justify-center my-4">
            {formatNumber(userData?.total_deposit || 0) > 500 ?
              <button
                onClick={() => navigate('/academy')}
                className="bg-lime-600 hover:bg-lime-500 text-white font-bold py-2 px-4 rounded-lg"
              >
                Go to Academy
              </button>
              :
              <button
                className="bg-gray-600  text-white font-bold py-2 px-4 rounded-lg cursor-not-allowed opacity-50"
              >
                Requerid min 500 USD in deposit
              </button>
            }
          </div>
        </div>
      </div>
      */}
      {/** Mi Package Barra */}
      <div className="flex flex-col lg:flex-row gap-4 h-auto my-4 lg:mt-16">
        <div className="w-full border-lime-600 border-2 bg-zinc-800 rounded-lg shadow-md h-auto text-white p-4 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${CardProfit})` }}>
          <div className="flex justify-between my-5 m-5">
            <p>{t("_PACKAGES._PACKAGE_3")}: {profitDay}%</p>
            <p>{t("_DASHBOARD._FINISH")}: {userData?.package_value_maximun || 0} USD</p>
          </div>
          <div className="relative h-8 rounded-2xl overflow-hidden bg-gray-300 my-5 mx-5">
            <div className="absolute top-0 h-8 rounded-2xl shim-green"
              style={{ width: `${percentage}%` }}>
            </div>
          </div>
          <div className="mx-5 flex flex-row justify-between my-5">
            <div className="flex flex-row justify-center items-center">
              <p className="hidden lg:block"> {t("_DASHBOARD._STATUS")}: {userData?.active === "1" ? t("_DASHBOARD._STATUS_ACTIVE") : t("_DASHBOARD._STATUS_INACTIVE")}</p>
              {userData?.package !== "8" && (
                <div className="mx-2 flex flex-row justify-between hidden lg:block">
                  <button className="text-md w-auto border-2 p-1 cursor-pointer rounded-lg" onClick={() => goToPackages()}>{t("_DASHBOARD._UPGRADE")} </button>
                </div>
              )}
            </div>
            <p> {t("_DASHBOARD._CURRENT")}: {userData?.package_value_current ? userData?.package_value_current : "0"} USD - {percentage ? percentage.toFixed(2) : "0"}%</p>
          </div>
        </div>
      </div>
      {userData?.active === "1" && (
        <div className="flex flex-col lg:flex-row gap-4 h-auto my-4">
          <div className="flex flex-col lg:flex-row justify-between w-full border-lime-600 border-2 bg-zinc-800 rounded-lg shadow-md h-auto text-white p-4 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${CardInvite})` }} onClick={() => copyShared(userData?.referred_my)}>
            <div className="mx-5 w-full lg:w-1/2 gap-4 flex flex-col justify-center">
              <p>{t("_DASHBOARD._SHARED")}</p>
              <p className="inline lg:hidden break-words  text-xs md:text-sm text-white my-6 ">
                <span className="block lg:hidden cursor-pointer bg-gray-800 text-white font-medium p-4 rounded my-4 border-lime-600 border-2 w-full lg:w-auto">
                  {userData?.referred_my}
                </span>
              </p>
              <p className="hidden lg:block  break-words  text-xs md:text-sm text-white my-6 ">
                <span className="cursor-pointer bg-gray-800 text-white font-medium p-4 rounded my-4 border-lime-600 border-2 w-full lg:w-auto">
                  {process.env.REACT_APP_FRONTEND}/signup?invite={userData?.referred_my}
                </span>
              </p>
            </div>
            <div className="lg:mx-5 w-full lg:w-1/2 flex lg:justify-end ">
              <QRCode
                size={128}
                bgColor={"#65A30D"}
                value={process.env.REACT_APP_FRONTEND + "/signup?invite=" + userData?.referred_my}
                className={"w-full lg:w-[20%] p-4 rounded-lg bg-lime-600 text-white"}
                viewBox={`0 0 128 128`}
              />
            </div>
          </div>
        </div>
      )}
      {/** Cuenta regresiva  + Disponibilidad
      {userData?.active && (
        <div className="w-full border-lime-600 border-2  bg-zinc-800 rounded-lg shadow-md h-auto text-white p-4" >
          <div className="w-full min-h-34 rounded-lg flex flex-col p-4 gap-2 bg-cover bg-center relative"  >
            <Countdown targetDate={userData?.activeDate} dateServer={hoursServer} />
          </div>
        </div >
      )}
        */}
      {/** Mi Profits Package List */}
      {userData?.active && (
        <div className="gap-4 h-auto ">
          <div className="w-full rounded-lg shadow-md h-auto text-white ">
            <div className="mx-5 flex flex-row justify-end my-5 items-center">
              <Link to="/withdrawal">
                <button className="text-md border-2 py-2 px-4 cursor-pointer rounded-lg" > {t("_WITHDRAWAL._TITLE")}</button>
              </Link>
            </div>
            <div className="flex flex-col lg:flex-row gap-4 p-4 h-auto w-full">
              <div className="relative overflow-x-auto w-full">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-lg ">
                  <thead className="text-md text-white border-b-2">
                    <tr>
                      <th className="px-6 py-4 ">
                        {t("_DASHBOARD._TABLE4_A")}
                      </th>
                      <th className="px-6 py-4">
                        {t("_DASHBOARD._TABLE4_B")}
                      </th>
                      {/*   <th className="px-6 py-4 ">
                        {t("_DASHBOARD._TABLE4_C")}
                      </th>
                 
                      <th className="px-6 py-4 ">
                        Hash
                      </th>
                      */}
                      <th className="px-6 py-4 ">
                        {t("_DASHBOARD._TABLE4_D")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {packagesOperation.map((item, index) => (
                      <tr className="border-b-2" key={index}>
                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                          {nameNotification(item?.action)}
                        </th>
                        <td className="px-6 py-4 text-white">
                          {item?.total_value ? formatNumber(item.total_value) : 0} USD
                        </td>
                        {/*   <td className="px-6 py-4 text-white">
                          {item?.message ? item.message + "%" : "-"}
                        </td>
                     
                        <td className="px-6 py-4 text-white">
                          {item?.link ? item.link : "-"}
                        </td>
                          */}
                        <td className="px-6 py-4 text-white">
                          {item?.created_date ? item.created_date : "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <nav className="flex items-center justify-center flex-column flex-wrap md:flex-row pt-4" aria-label="Table navigation">
                  <ul className="flex justify-center items-center h-8">
                    {page > 1 && (
                      <li>
                        <button className="flex items-center justify-center px-3 h-12 border-2 rounded-lg text-white" onClick={() => setPage((prev) => Math.max(prev - 1, 1))}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                          </svg>
                          {t("_DASHBOARD._PREV")}
                        </button>
                      </li>
                    )}
                    <li>
                      <button className={`flex items-center justify-center px-3 h-12 text-white mx-6`}>
                        {page} / {Math.ceil(total / limit)}
                      </button>
                    </li>
                    {page < Math.ceil(total / limit) && (
                      <li>
                        <button className="flex items-center justify-center px-3 h-12 border-2 rounded-lg text-white" onClick={() => setPage((prev) => Math.min(prev + 1, Math.ceil(total / limit)))}>
                          {t("_DASHBOARD._NEXT")}
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                          </svg>
                        </button>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </div >
  );
}
