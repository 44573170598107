import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import AppContext from "../context/AppContext";
import { formatDistanceToNow, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';
import toast from "react-hot-toast";
import CustomButton from '../components/CustomButton';

export default function MySignals() {
  const { t } = useTranslation();
  const [wallet, setWallet] = useState();
  const [listDeposit, setListDeposit] = useState([]);
  const { loading, updateLoading } = useContext(AppContext);


  useEffect(() => {
    // Agregar el código de LiveChat
    window.__lc = window.__lc || {};
    window.__lc.license = 18937954;
    window.__lc.integration_name = "manual_onboarding";
    window.__lc.product_name = "livechat";

    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.src = "https://cdn.livechatinc.com/tracking.js";
    document.head.appendChild(script);

    return () => {
      // Limpieza del script si el componente se desmonta
      document.head.removeChild(script);
    };
  }, []); // Este `useEffect` se ejecuta solo una vez al montar el componente


  return (
    <div className="mx-auto lg:p-4">
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="bg-zinc-800 text-white p-6 rounded-lg w-full lg:w-2/6 border-lime-600 hover:border-lime-300 border-2">
          <h2 className="text-lg font-semibold mb-2">Enlace de Comunidad</h2>
          <p className="text-left text-md mb-4">Unete a nuestra comunidad de WhatsApp para obtener información inmediata y en tiempo real</p>
          <div className="flex justify-center items-center w-full">
            <QRCode
              size={256}
              bgColor={"#65A30D"}
              value={wallet ? wallet : "Loading"}
              className={"w-4/6 p-2 rounded-lg bg-lime-600"}
              viewBox={`0 0 256 256`}
            />
          </div>
        </div>
        <div className="bg-zinc-800 p-6 rounded-lg shadow w-full lg:w-4/6 flex flex-col text-left h-auto relative border-lime-600 hover:border-lime-300 border-2">
          <div className="flex flex-col w-full p-4">
            {/* Aquí se cargará el chat */}
          </div>
        </div>
      </div>
    </div >
  );
}
