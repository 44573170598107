import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Range1 from "../assets/svg/range/1.svg";
import Range2 from "../assets/svg/range/2.svg";
import Range3 from "../assets/svg/range/3.svg";
import Range4 from "../assets/svg/range/4.svg";
import Range5 from "../assets/svg/range/5.svg";
import Range6 from "../assets/svg/range/6.svg";
import Range7 from "../assets/svg/range/7.svg";
import Range8 from "../assets/svg/range/8.svg";

const GlobalVolumen = ({ userData, rangeData }) => {
    const { t } = useTranslation();
    const [currentLevel, setCurrentLevel] = useState({});
    const [nextLevel, setNextLevel] = useState({});

    const levels = [
        {
            image: Range1,
            level: 0,
            name: "Level 0",
            globalVolume: 0,
            globalVolumeR: 0,
            requirements: [{ name: 0, qty_available: 0, qty: 0 }, { name: 0, qty_available: 0, qty: 0 },]
        },
        {
            image: Range2,
            level: 1,
            name: "Silver",
            globalVolume: 10000,
            globalVolumeR: "10K",
            lines: 0,
            requirements: [{ name: 0, qty_available: 0, qty: 0 }, { name: 0, qty_available: 0, qty: 0 },]
        },
        {
            image: Range3,
            level: 2,
            name: "Gold",
            globalVolume: 25000,
            globalVolumeR: "25K",
            lines: 2,
            requirements: [{ name: 1, qty_available: 0, qty: 2 }, { name: 0, qty_available: 0, qty: 0 }],
        },
        {
            image: Range4,
            level: 3,
            name: "Ruby",
            globalVolume: 100000,
            globalVolumeR: "100K",
            lines: 3,
            requirements: [{ name: 1, qty_available: 0, qty: 2 }, { name: 2, qty_available: 0, qty: 1 },]
        },
        {
            image: Range5,
            level: 4,
            name: "Esmeralda",
            globalVolume: 300000,
            globalVolumeR: "300K",
            lines: 3,
            requirements: [{ name: 2, qty_available: 0, qty: 2 }, { name: 3, qty_available: 0, qty: 1 },]
        },
        {
            image: Range6,
            level: 5,
            name: "Diamond",
            globalVolume: 1000000,
            globalVolumeR: "1M",
            lines: 3,
            requirements: [{ name: 3, qty_available: 0, qty: 2 }, { name: 4, qty_available: 0, qty: 1 },]
        },
        {
            image: Range7,
            level: 6,
            name: "Diamond Blue",
            globalVolume: 3000000,
            globalVolumeR: "3M",
            lines: 3,
            requirements: [{ name: 4, qty_available: 0, qty: 2 }, { name: 5, qty_available: 0, qty: 1 },]
        },
        {
            image: Range8,
            level: 7,
            name: "Diamong Double Blue",
            globalVolume: 10000000,
            globalVolumeR: "10M",
            lines: 3,
            requirements: [{ name: 5, qty_available: 0, qty: 2 }, { name: 6, qty_available: 0, qty: 1 },]
        },
    ];
    useEffect(() => {
        // Aquí calculamos el nivel actual basado en el volumen global del usuario
        const userVolume = userData?.range;
        let current = levels[0]; // Por defecto el primer nivel
        let next = levels[1]; // El siguiente nivel

        // Reiniciar qty_available a 0 antes de actualizar para evitar acumulaciones incorrectas
        levels.forEach((level) => {
            level.requirements.forEach((requirement) => {
                requirement.qty_available = 0;
            });
        });

        // Iterar sobre el array de rangeData y actualizar qty_available en levels
        rangeData.forEach((rangeObj) => {
            const rangeValue = parseInt(rangeObj.range, 10); // Convertir el valor a número

            levels.forEach((level) => {
                level.requirements.forEach((requirement) => {
                    if (requirement.name === rangeValue) {
                        requirement.qty_available += 1; // Incrementar si el name coincide con el range
                    }
                });
            });
        });

        // Encontramos el nivel actual y el siguiente nivel basado en el volumen del usuario
        for (let i = 0; i < levels.length; i++) {
            if (userVolume >= levels[i].level) {
                current = levels[i];
                next = levels[i + 1] || levels[i]; // Si es el último nivel, no hay siguiente
            } else {
                break;
            }
        }
        console.log(levels)
        setCurrentLevel(current);
        setNextLevel(next);
    }, [userData, rangeData]);




    const formatNumber = (value) => {
        if (value >= 1000000) {
            return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        if (value >= 1000) {
            return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        return value.toString();
    }


    const nameRange = (name) => {
        if (name === 0) {
            return t("_GLOBALV._NONE");
        } else if (name === 1) {
            return t("_GLOBALV._SILVER");
        } else if (name === 2) {
            return t("_GLOBALV._GOLD");
        } else if (name === 3) {
            return t("_GLOBALV._RUBY");
        } else if (name === 4) {
            return t("_GLOBALV._EMERALD");
        } else if (name === 5) {
            return t("_GLOBALV._DIAMOND");
        } else if (name === 6) {
            return t("_GLOBALV._DIAMONDB");
        } else if (name === 7) {
            return t("_GLOBALV._DIAMONDBB");
        }
    }


    return (
        <>
            <div className="flex flex-col w-full lg:w-[23%] lg:min-h-full lg:h-auto">
                <div className="flex flex-col py-1 px-4 rounded-lg shadow-md w-full border-lime-600 hover:border-lime-300 border-2 h-full bg-zinc-800 gap-2">
                    <div className="w-full text-white flex flex-row my-2 gap-2">
                        <h1 className="text-2xl">{t("_GLOBALV._TITLE")}:</h1>
                        <h1 className="text-2xl">{formatNumber(userData.range_volumen || 0)} USD</h1>
                    </div>
                    <div className="flex flex-row p-1 gap-2 justify-center items-center">
                        <div className="text-white w-full lg:w-[30%]">
                            <h1 className="text-lime-300 my-2">{t("_GLOBALV._CURRENT")}</h1>
                            <p className='font2'>{nameRange(currentLevel.level)}</p>
                            <p className='font2'>{currentLevel.globalVolumeR} USD</p>
                        </div>
                        <div className="border-2 border-lime-300 bg-black rounded-3xl h-full w-full lg:w-[70%] relative max-h-20">
                            <img src={currentLevel.image} className="mx-auto lg:absolute top-[-1.5rem] left-1/2 transform  lg:-translate-x-1/2 w-20 lg:w-24" alt="Range" />
                        </div>
                    </div>

                    <hr className="h-px my-2 border-lime-300 border-dashed" />

                    {/* Próximo nivel */}
                    <div className="flex flex-row p-1 gap-2 justify-center items-center">
                        <div className="text-white w-full lg:w-[30%]">
                            <h1 className="text-lime-300 my-2">{t("_GLOBALV._NEXT")}</h1>
                            <p className='font2'>{nameRange(nextLevel.level)}</p>
                            <p className='font2'>{nextLevel.globalVolumeR} USD</p>
                        </div>
                        <div className="border-2 border-lime-300 bg-black rounded-3xl h-full w-full lg:w-[70%] relative max-h-20">
                            <img src={nextLevel.image} className="mx-auto lg:absolute top-[-1.5rem] left-1/2 transform  lg:-translate-x-1/2 w-20 lg:w-24" alt="Range" />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center gap-2 items-center text-white mt-2 text-sm">
                        {nextLevel.requirements && nextLevel.requirements.map((req, index) => (
                            <div key={index} className="flex flex-row justify-left gap-1 items-center w-full font-2 p-1">
                                <p className="w-auto font-2">{req.qty_available}</p>
                                <div className="w-3/5 bg-gray-600 rounded-full h-4">
                                    <div className="bg-lime-600 h-4 rounded-full" style={{ width: `${(((req.qty_available > req.qty ? req.qty : req.qty_available) / req.qty) * 100)}%` }} ></div>
                                </div>
                                <p className="w-auto  font-2"> {`${req.qty} ${nameRange(req.name)}`}</p>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </>
    );
};

export default GlobalVolumen;
