import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import AppContext from "../context/AppContext";
import Logo from "../assets/svg/signals/logo.svg";
import Footer from "../assets/svg/signals/footer.svg";
import Chat from "../assets/svg/signals/chat.svg";

export default function Signals() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const { loading, updateLoading } = useContext(AppContext);

    const testimonials = [
        {
            username: '@petfew',
            text: 'I started using these signals on the Solana network and managed to turn my initial $200 into $600 in just a few hours. The insights provided through Dexscreener made all the difference!'
        },
        {
            username: '@nattef',
            text: 'With these real-time signals, I’ve consistently multiplied my initial investment week after week. The combination of Solana and Dexscreener data helped me gain over $3,000 in just 5 weeks!'
        },
        {
            username: '@johndoe',
            text: 'The community behind these signals is incredible. Not only did I increase my holdings significantly on Solana, but I also found a super supportive group that guided me through every trade.'
        },
        {
            username: '@moonshot',
            text: 'I was skeptical at first, but the synergy between Solana-based opportunities and Dexscreener insights proved me wrong. My portfolio has grown beyond what I ever imagined!'
        }
    ];

    const [selectedTestimonialIndex, setSelectedTestimonialIndex] = useState(0);

    return (
        <>
            {/* HERO SECTION */}
            <section className="relative bg-black text-white overflow-hidden font-sans">
                {/* Botón de regresar en la esquina superior derecha */}
                <div className="absolute top-4 left-4 z-10">
                    <button
                        onClick={() => navigate("/")}
                        className="bg-[color:#7EFF00] hover:bg-[color:#9AFF40] text-black font-bold py-2 px-4 rounded transition"
                    >
                        Back
                    </button>
                </div>

                <div className='flex flex-col justify-center items-center p-6 z-10 cursor-pointer'>
                    <img src={Logo} alt="Cryptotradex Signals" className='w-[10%] cursor-pointer z-10 ' onClick={() => navigate("/")} />
                </div>
                <div className="absolute inset-0 flex justify-center items-center -top-[50%] z-1">
                    <span className="[color:transparent] [-webkit-text-stroke:2px_#FFFFFF33] [-webkit-text-fill-color:transparent] font-bold text-[18vw] uppercase">
                        SIGNALS10X
                    </span>
                </div>
                <div className="relative z-10 max-w-7xl mx-auto px-4 py-14 flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-8">
                    <div className="flex-1 space-y-6">
                        <div className="flex items-center space-x-2">
                            <span className="inline-block bg-[color:#7EFF00] text-black font-black py-1 px-2 text-lg">CT SIGNS</span>
                        </div>
                        <h2 className="text-3xl md:text-5xl font-bold text-[color:#7EFF00]">Exclusive Chat</h2>
                        <p className="text-lg max-w-md">
                            In our exclusive chat, you'll receive real-time signals, and also be able to contribute or ask questions about each one in an organized manner.
                        </p>

                        <div className="flex space-x-4 text-[color:#7EFF00]">
                            <span className="w-8 h-8 bg-white text-black flex items-center justify-center rounded-full">💬</span>
                            <span className="w-8 h-8 bg-white text-black flex items-center justify-center rounded-full">🤖</span>
                            <span className="w-8 h-8 bg-white text-black flex items-center justify-center rounded-full">✨</span>
                        </div>
                    </div>

                    <div className="flex-1 flex justify-end items-center relative -px-4">
                        <img src={Chat} alt="Chat" />
                    </div>
                </div>
            </section>

            {/* TESTIMONIALS SECTION */}
            <section className="relative bg-black text-white py-36 overflow-hidden">
                <div className="absolute inset-0 flex justify-center items-center -top-[68%]">
                    <span className="[color:transparent] [-webkit-text-stroke:2px_#FFFFFF33] [-webkit-text-fill-color:transparent] text-[18vw] uppercase">
                        TESTIMONIALS
                    </span>
                </div>

                <div className="relative z-10 mx-auto px-4 flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-8">
                    {/* TESTIMONIAL CARD */}
                    <div className="relative w-full md:w-1/2">
                        <div className="transform rotate-6 bg-[color:#7EFF00] p-8 h-80 w-full flex flex-col justify-center items-center">
                            <div className="transform -rotate-6 text-black w-full">
                                <span className="text-[8rem] leading-none">“</span>
                                <p className="text-xl font-bold mb-4 text-center">
                                    {testimonials[selectedTestimonialIndex].text}
                                </p>
                                <span className="text-[8rem] leading-none block text-right">”</span>
                            </div>
                        </div>
                    </div>

                    {/* TESTIMONIAL USERS LIST */}
                    <div className="w-full md:w-1/2 flex items-center justify-center space-x-4">
                        <button className="text-xl cursor-pointer">←</button>
                        <div className="flex space-x-4">
                            {testimonials.map((item, index) => (
                                <div
                                    key={item.username}
                                    onClick={() => setSelectedTestimonialIndex(index)}
                                    className={`flex flex-col items-center space-y-2 cursor-pointer border-2 p-2 transition-colors ${selectedTestimonialIndex === index
                                        ? 'border-gray-700'
                                        : 'border-transparent hover:border-gray-700'
                                        }`}
                                >
                                    <img src="https://via.placeholder.com/100" alt="User" className="rounded-xl shadow-lg" />
                                    <span className="text-[color:#7EFF00]">{item.username}</span>
                                </div>
                            ))}
                        </div>
                        <button className="text-xl cursor-pointer">→</button>
                    </div>

                </div>
            </section>

            {/* FINAL CTA SECTION */}
            <section className="relative bg-black text-white py-32 overflow-hidden p-20">
                <div className="absolute inset-0 flex justify-center items-center -top-[50%]">
                    <span className="[color:transparent] [-webkit-text-stroke:2px_#FFFFFF33] [-webkit-text-fill-color:transparent] text-[18vw] uppercase">
                        GOOOOOOO
                    </span>
                </div>
                <div
                    className="relative z-10 mx-auto px-4 rounded-xl border border-[color:#7EFF00] py-10 text-center bg-cover bg-center m-10"
                    style={{ backgroundImage: `url(${Footer})` }}
                >
                    <div className="flex flex-col justify-center items-center p-6">
                        <img src={Logo} alt="Cryptotradex Signals" className="w-[10%]" />
                    </div>
                    <p className="mb-8">
                        Get daily CTX signals and start multiplying your capital!
                    </p>
                    <p className="mb-8 text-sm">
                        To be part of Cryptotradex Signals, you must have an investment equal or greater than $1,000 USD.
                    </p>
                    <button className="bg-[color:#7EFF00] hover:bg-[color:#9AFF40] text-black font-bold py-3 px-6 rounded transition" onClick={() => navigate("/")}>
                        I want the signals
                    </button>
                </div>
            </section>
        </>
    );
}
